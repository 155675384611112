<!--
 * @Description: 批量新增弹层
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-12 17:03:34
 * @LastEditTime: 2020-09-28 15:24:31
 -->
<template>
  <div style="margin: 0px">
    <a-modal
      :title="usertitle"
      class="batch-upload-goods-dialog"
      style="width: 1200px"
      v-model="batchUploadGoodsDialogVisible"
      centered
      :bodyStyle="{ padding: 0 }"
      :footer="null"
      @cancel="hideImportFile"
    >
      <div style="height: 100px;text-align: center;line-height: 100px;position: relative;">
        <a-form style="height: 40px;position:absolute;margin-top: 30px;width: 100%;">
        <a-form-item style="padding-left: 20px;width: 100%;height:40px;text-align: center" label="推荐人员" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
          <a-select
            placeholder="请选择推荐人"
            v-model="recommend_user_id"
            :default-active-first-option="false"
            show-search
            :allowClear="true"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleSearchSupplier"
          >
<!--            -->
            <a-select-option v-for="item in supplierPeopleList"   :value="item.user_id"
                             :disabled="item.checked"    :key="item.user_id" >{{ item.username }}</a-select-option>
          </a-select>
        </a-form-item>
        </a-form>
      </div>

      <div class="ant-modal-footer" >
        <div>
          <button type="button" class="ant-btn ant-btn-normal" @click="hideImportFile">
            <span>取消</span>
          </button>
          <button type="button" class="ant-btn ant-btn-primary" @click="handleUploadPeople">
            <span>保存</span>
          </button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
  import { RecommenduserList , editUserInfo } from "@/service/user";
  // import { supplieruserList } from '@/service/supplier'
export default {
  name: 'ImportFile',
  props: {
    searchData: {
      default: function () {
        return {};
      },
      type: Object
    },
    selectCommendUservisable:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recommend_user_id:"",
      batchUploadGoodsDialogVisible: true,
      people:"",
      supplierPeopleList:[],
    };
  },
  created() {
    if(this.searchData){
      this.recommend_user_id=this.searchData.recommend_user_id===0?'':this.searchData.recommend_user_id;
    }
    this.handleSearchSupplier('');
  },
  computed:{
    usertitle: function () {
      if(this.searchData) {
        var str = this.searchData.nickname || this.searchData.mobile;
        if (str.length > 1) {
          return '填写 ' + str + ' 的推荐人';
        } else {
          return '填写推荐人';
        }
      }else{
        return '填写推荐人';
      }
    }
  },
  methods: {
    //获取推荐人
    handleSearchSupplier(str) {
      var that=this;
      RecommenduserList({
        search: str
      }).then((res) => {
        if (res.res.success === true) {
          that.supplierPeopleList = res.res.data.results;
          var ishand=false;
          that.supplierPeopleList=this.supplierPeopleList.map(item=>{
            if(that.searchData.recommend_user_id===item.user_id) {
              ishand=true;
            }
            item.checked=false;
            return item;
          });
          if(ishand===false&&that.searchData.recommend_user_id!==0&&that.searchData.recommend_user_id!==""){
            that.supplierPeopleList.push({
              checked: true,
              user_id: that.searchData.recommend_user_id,
              username: that.searchData.recommend_user_username,
            })
          }
        }
      })
    },
    // 隐藏
    hideImportFile() {
      this.$emit('callback');
    },
    // 手动上传
  async  handleUploadPeople() {
      // this.$emit('submitClick', this.selectlist);
      const { err, res } = await editUserInfo(this.searchData.id,{
        recommend_user_id: this.recommend_user_id
      });
      if (!err) {
        if (res.success) {
          this.$message.success("添加成功！");
          this.$emit('callback');
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.batch-upload-goods-dialog {
  width: 100% !important;
  .mb-10 {
    margin-bottom: 10px;
  }
  .upload-spin {
    text-align: center;
  }
  .lh-120 {
    line-height: 125%;

    p {
      margin-bottom: 20px !important;
    }
  }
  align-items: flex-start;
  justify-content: flex-start;
  justify-self: flex-start;
  /deep/ .ant-modal {
    width: 400px !important;

    justify-self: center;
    align-items: center;
    .ant-modal-content {
      /*width: 1200px !important;*/
      padding: 0 0px 0 0px;
      text-align: left;
      .ant-modal-centered {
        /*width: 1200px;*/
      }
    }
    .ant-modal-wrap .ant-modal-centered {
      align-items: flex-start;
    }
  }

}
</style>
