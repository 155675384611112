<!--
 * @Description: 用户列表
 * @Date: 2019-12-24 15:17:08
 * @LastEditTime: 2020-09-28 15:29:44
 * @LastEditors: 矢车
 -->
<template>
  <div id='list'>
    <UserSearchBar
        v-auth="'goods_manage_goods_list'"
        @callback="searchBarCallback"
        :searchParams="searchParams"
        :opeartors="user_brief_list"
        :loading="listLoading"
      />
    <a-table class="ant-table-body-inner"
      :scroll="{ x: 1200, y: tableScrollY }"
      v-auth="'user_manage_user_list'"
      :rowKey="record => record.id"
      :columns="columns"
      :pagination="pagination"
      :dataSource="list"
      :locale="{ emptyText: '未找到相关用户' }"
      :loading="userListLoading"
      @change="handleTableChange"
    >
   <!--   <template slot="channel_tag" slot-scope="record">
        <a-tag v-if="record.channel===2" color="orange">淘宝</a-tag>
        <a-tag v-if="record.channel===1" color="purple">抖音</a-tag>
      </template>-->
       <template slot="fans" slot-scope="record">
         <template v-if="record.channel === 2">
        <span v-if="record.taobao_fans_count === -1">-</span><span v-else>{{record.taobao_fans_count}}</span>
        </template>
        <template v-else>
        <span v-if="record.douyin_fans_count === -1">-</span><span v-else>{{record.douyin_fans_count}}</span>
        </template>
      </template>
      <template slot="co_op_mode" slot-scope="record">
        <span>{{ record.co_op_mode | coopModeFilter }}</span>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button size="small" @click="handleDetailClick(record)" v-auth="'user_manage_user_detail_page'">查看</a-button>
        <a-button v-if="isEdit" style="margin-left: 10px;" size="small" @click="showcommendUserClick(record)">推荐人</a-button>
      </template>
      <template slot="money" slot-scope="money">
        <span :style="`${money == 0 ? 'color:#ccc;' : ''}`">￥{{money}}</span>
      </template>
      <!-- <template slot="douyin" slot-scope="record">
        <span v-if="record.channel === 1">{{record.nickname}}</span>
      </template>
       <template slot="taobao" slot-scope="record">
        <span v-if="record.channel === 2">{{record.nickname}}</span>
      </template> -->
      <!-- <template slot="fansSlot" slot-scope="record">
        <span v-if=""></span>
      </template> -->
    </a-table>
    <a-drawer
      title="用户详情"
      placement="right"
      width="1050"
      @close="handleUserInfoDrawerClose"
      :visible="userDetailDrawerVisiable"
      :destroyOnClose="true"
    >
      <UserDtail ref="userDetail" :id="drawerProps.id" :opeartors="user_brief_list" />
    </a-drawer>
    <selectCommendUser
      :selectCommendUservisable="selectCommendUservisable"
      v-if="selectCommendUservisable"
      :searchData="searchData"
      @callback="importFileCallback"
    >
    </selectCommendUser>
  </div>
</template>

<script>


  const columns = [
  {
    title: '手机号',
    dataIndex: 'mobile',
    fixed: 'left',
    align: 'center',
    width: 180,
  },
 /* {
    title: '渠道',
    align: 'center',
    scopedSlots: { customRender: 'channel_tag' },
    width: 120,
  },*/
  {
    title: '推荐人',
    dataIndex: 'recommend_user_username',
    align: 'center',
    width: 120,
  },
  // {
  //   title: '抖音号',
  //   dataIndex: 'douyin_account',
  //   align: 'center'
  // },
  {
    title: '昵称',
    dataIndex: 'nickname',
    align: 'center',
    width: 120,
  },

  //  {
  //   title: '主播昵称',
  //   // dataIndex: 'anchor_name',
  //   align: 'center',
  //   scopedSlots: { customRender: 'taobao' },
  // },
   {
    title: '粉丝数',
    align: 'center',
    scopedSlots: { customRender: 'fans' },
    width: 100,
  },
  // {
  //   title: '真实姓名',
  //   dataIndex: 'name',
  //   align: 'center'
  // },
  {
    title: '账户余额',
    dataIndex: 'money',
    scopedSlots: { customRender: 'money' },
    align: 'center',
    width: 150,
  },
  {
    title: '合作模式',
    scopedSlots: { customRender: 'co_op_mode' },
    width: 120,
    align: 'center'
  },
  {
    title: '注册时间',
    dataIndex: 'create_time',
    width: 220,
    align: 'center'
  },
  {
    title: '最后访问',
    dataIndex: 'last_active_time',
    width: 220,
    align: 'center'
  },
  {
    title: '操作',
    key: 'operation',
    align: 'center',
    fixed: 'right',
    width: 180,
    scopedSlots: { customRender: 'operation' }
  }
]

import { getUsers, getAdminUserBriefList } from '../../../service/user'
import { USER_STATUS } from '../../../const/index'
import { checkPer } from "@/utils/index";
import UserDtail from '../../../components/admin/user-detail'
import UserSearchBar from '@/components/admin/user/user-list-search-bar.vue'
  import selectCommendUser from '@/components/admin/user/select-recommend-user';

export default {
  name: 'list',
  components: { UserDtail, UserSearchBar,selectCommendUser },
  data () {
    return {
      namestr:'oooo',
      selectCommendUservisable: false,
      tableScrollY: 600,
      form: this.$form.createForm(this),
      columns,
      page: 1,
      list: [],
      listLoading: true,
      userListLoading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: total => `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(total / this.pagination.pageSize)} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => this.pagination.pageSize = pageSize
      },
      userDetailDrawerVisiable: false,
      drawerProps: { id: null },
      searchParams:{
        id: this.$route.query.id || undefined,
        star_docking_user: undefined,
        operate_docking_user: undefined,
        level: undefined,
        // channel: undefined,
        search: '',
        time_type: undefined,
        start_time: undefined,
        end_time: undefined,
        recommend_user_id: undefined,
      },
      user_brief_list:[],
      searchData:{},
      isEdit:false,
    }
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 300;
    if (this.$store.state.user.rights.includes("user_manage_modify_edit_recommend_user")) {
      this.isEdit=true;
    }
  },
  mounted () {
    this.getUserList();
    // 获取运营列表
    this.getAdminUserBriefListData();
    // 如果路由带了id，则显示详情
    const path = this.$route.path;
    if(path.includes('detail')
      || this.$route.query.showDetail) {
      const id = this.$route.params.id || this.$route.query.id;
      this.userDetailDrawerVisiable = true
      this.$nextTick(() => {
        this.drawerProps.id = id
        this.$refs.userDetail.init(id)
      })
    }
    },

    methods: {

    showcommendUserClick(record){
      this.searchData=record;
      this.selectCommendUservisable=true;
    },
    // 上传完成回调
    importFileCallback() {
      this.getUserList();
      this.selectCommendUservisable = false;
    },
    // 申请列表搜素表单submit, 执行搜索
    searchBarCallback(res) {
      this.pagination.current = 1;
      this.searchParams = Object.assign({}, res);
      this.getUserList();
    },
    // 获取用户列表
    async getUserList () {
      if (!checkPer("user_manage_user_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      this.userListLoading = true
      const data = {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        status: USER_STATUS.PASSED.VALUE,
        ordering: '-create_time',
        ...this.searchParams
      }
      const { err, res } = await getUsers(data)

      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination }
          this.list = res.data.results
          pagination.total = res.data.count
          this.pagination = pagination
        }
      }
      this.userListLoading = false
    },
    // 跳转详情
    handleDetailClick (user) {
      // this.$router.push({ path: '/admin/user/detail' })
      this.drawerProps.id = user.id;
      this.userDetailDrawerVisiable = true;
      // debugger
      this.$nextTick(() => {

        this.$refs.userDetail.init(this.drawerProps.id)
      })

    },
    // 表格分页
    handleTableChange (pagination) {
      if (pagination) this.pagination.current = pagination.current
      this.getUserList()
    },
    // 用户信息抽屉关闭回调
    handleUserInfoDrawerClose () {
      this.userDetailDrawerVisiable = false
      this.getUserList()
    },
    // 搜索框数据发生变化
    handleSearchInputChange (e) {
      if (e.target.value === '') this.getUserList()
    },
    // 获取后台用户简略信息列表
    async getAdminUserBriefListData() {
      const { err, res } = await getAdminUserBriefList();
      if (!err) {
        if (res.success) {
          this.user_brief_list = res.data;
        }
      }
    },
  }
}
</script>

<style lang='less' scoped>
#list {
  /deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    padding-bottom: 2px;
  }
  /deep/ .ant-table-body {
    //样式穿透
    overflow-x: auto !important;
  }
}
.mb-10 {
  margin-bottom: 10px;
}
</style>
