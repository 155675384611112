<!--
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-03-05 16:44:54
 * @LastEditTime: 2020-08-08 14:46:04
 * @Description: 拼播管理搜索条
 -->
<template>
  <div class="mb-10">
    <a-form
      class="filter-condition-form mb-10"
      layout="inline"
      :form="form"
      @submit="handleFormSubmit"
      v-auth="'user_manage_user_list'"
    >
      <a-form-item>
        <a-input
          style="width:250px;"
          allowClear
          v-decorator="['searchParams.search']"
          placeholder="注册手机/抖音号/抖音昵称/真实姓名/淘宝客PID/淘宝userID/主播昵称"
          @pressEnter="handleFormSubmit"
        />
      </a-form-item>
     <!-- <a-form-item>
        <a-select
          style="width: 120px"
          v-decorator="['searchParams.channel']"
          placeholder="渠道筛选"
          allowClear
        >
          <a-select-option value="1">抖音</a-select-option>
          <a-select-option value="2">淘宝</a-select-option>
        </a-select>
      </a-form-item>-->
      <a-form-item>
        <a-select
          style="width: 120px"
          v-model="searchParams.recommend_user_id"
          placeholder="推荐人"
          allowClear
          show-search
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearchSupplier"
        >
          <a-select-option
            v-for="(item) in supplierPeopleList"
            :value="item.user_id"
            :key="item.user_id"
          >{{ item.username }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select
          style="width: 120px"
          v-decorator="['searchParams.co_op_mode']"
          placeholder="合作模式"
          allowClear
        >
          <a-select-option
            v-for="(item, index) in coopModeOptions"
            :value="item.value"
            :key="index"
            >{{ item.label }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select
          style="width: 120px"
          v-decorator="['searchParams.level']"
          placeholder="折一等级"
          allowClear
        >
          <a-select-option :value="i.value" v-for="i in userLevelOption" :key="i.value">{{i.label}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select
          style="width: 120px"
          v-decorator="['searchParams.star_docking_user']"
          placeholder="达人对接"
          allowClear
        >
          <a-select-option :value="i.id" v-for="i in opeartors" :key="i.id">{{i.username}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select
          style="width: 120px"
          v-decorator="['searchParams.operate_docking_user']"
          placeholder="平台对接"
          allowClear
        >
          <a-select-option :value="i.id" v-for="i in opeartors" :key="i.id">{{i.username}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select
          style="width: 130px;margin-right:10px"
          placeholder="时间类型"
          v-decorator="['searchParams.time_type']"
          @change="onChangeTimeType"
          allowClear
        >
          <a-select-option v-for="item in timeTypeOptions" :key="item.value">{{item.label}}</a-select-option>
        </a-select>
        <a-range-picker
          v-if="searchParams.time_type"
          format="YYYY-MM-DD"
          v-decorator="['range_time', { rules: [{ required: true, message: '请选择时间' }]}]"
          @change="onChangeRangeTime"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit">查询</a-button>
      </a-form-item>
      <a-form-item v-auth="'user_manage_export_user'">
        <a-button type="primary" :loading="isExporting" @click="exportUser">导出</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { userLevelOption } from "@/const/index";
import { getExportUser,RecommenduserList } from "@/service/user.js";
import { coopModeOptions } from '@/const/index';

// 时间类型
const timeTypeOptions = [
  { label: "注册时间", value: 1 },
  { label: "评级时间", value: 2 },
  { label: "最后访问时间", value: 3 }
];
export default {
  name: "UserListSearchBar",
  props: {
    searchParams: {
      default: function() {
        return {
          ordering: "-create_time"
        };
      },
      type: Object
    },
    loading: {
      default: false,
      type: Boolean
    },
    opeartors: {
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
      userLevelOption: [{label: '未评级', value: '未评级'}, ...userLevelOption],
      timeTypeOptions,
      isExporting: false,
      coopModeOptions,
      supplierPeopleList:[],
    };
  },
  created() {
    this.handleSearchSupplier();
  },
  methods: {
    //获取推荐人
    handleSearchSupplier(str) {
      RecommenduserList({
        search: str
      }).then((res) => {
        if (res.res.success === true) {
          this.supplierPeopleList = res.res.data.results;
        }
      })
    },
    // 申请列表搜素表单submit
    handleFormSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, res) => {
        if (!err) {
          if (!this.searchParams.time_type) {
            this.searchParams.start_time = undefined;
            this.searchParams.end_time = undefined;
          }
          this.$emit("callback", {
            star_docking_user: res.searchParams.star_docking_user,
            operate_docking_user: res.searchParams.operate_docking_user,
            level: res.searchParams.level,
            // channel: res.searchParams.channel,
            co_op_mode: res.searchParams.co_op_mode,
            search: res.searchParams.search,
            time_type: res.searchParams.time_type,
            start_time: this.searchParams.start_time,
            end_time: this.searchParams.end_time,
            recommend_user_id: this.searchParams.recommend_user_id,
            range_time: undefined
          });
        }
      });
    },
    // 修改查询时间段
    onChangeRangeTime(e, str) {
      this.range_time = str;
      this.searchParams.start_time = str[0];
      this.searchParams.end_time = str[1];
    },
    // 修改时间类型
    onChangeTimeType(e) {
      this.searchParams.time_type = e;
      if (!this.searchParams.time_type) {
        this.searchParams.start_time = undefined;
        this.searchParams.end_time = undefined;
      }
    },
    // 导出用户
    async exportUser(){
      const mainParams = this.form.getFieldsValue().searchParams
      const params = {...this.searchParams, ...mainParams};
      this.isExporting = true
      const {err, res} = await getExportUser(params);
      this.isExporting = false
      if(!err && res.success){
        window.open(res.data.detail_url);
      }

    }
  }
};
</script>
<style lang='less' scoped>
.option-btns {
  margin-bottom: 20px;
  margin-top: 10px;
  .option-btn {
    margin-right: 10px;
  }
}
.mb-10 {
  margin-bottom: 10px;
}
</style>
